@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@600&display=swap');

*{
  margin: 0;
  padding: 0;
  user-select: none;
  scroll-behavior: smooth;
}
body{
  font-family: 'Rajdhani', sans-serif !important;
  background: #f5f5f5;

}
.norrr .demoo {
  color: #414141;
  font-weight: 600;
  width: 100%;
  height: 80px;
  font-size: 32px;
}
.app{
  background: #f5f5f5;
  padding-left: 104px;
}
.address{
  width: 55%;
}
span, .lines{
  font-size: 20px;
}
.whyzeem{
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  list-style-type: none;
  padding: 2% 0;
  display: flex;

}
.circle-list {
  list-style-type: none;
}
.whyzeem img {
  width: 10px;
    height: 10px;
    padding-top: 11.5px;
    padding-right: 15px;
}
.headerlogo ul{
  list-style: none;
  display: flex;
  padding-left: 121px;
}
.headerlogo ul li a{
  font-size: 16px;
  color: #898989;
  text-decoration: none;
  transition: all 0.3s ease;
}
.headerlogo{
  display: flex;
  align-items: center;
  justify-items: center;
  background: #f5f5f5;
  padding: 0 104px;
  padding-top: 32px;
  justify-content: space-between;
}
.lines{
  display: flex;
  padding-right: 104px;
  padding-bottom: 3%;
}
.lineone{
  width: 25%;
  height: 20px;
  border-bottom: 4px solid #808080;
}
.linetwo{
  width: 100%;
  height: 20px;
  border-bottom: 3px solid #C3C3C3;
  align-self: center;
}
.services li{
  font-style: normal;
  line-height: 30px;
}
.services{
  text-align: center;
}
.companieslogo{
  display: flex;
  justify-content: center;
  z-index: 1;
  position: relative;
  margin: 5% 0;
}
.analysedeif{
  width: 100%;
  height: 60px;
  background: white;
}
.lists{
  display: grid;
  grid-template-columns: 50% 40%;
  padding-left: 5px;
  justify-content: space-between;
}
.partners li {
  font-size: 20px;
  color: #6C6C6C;
  padding-bottom: 5%;
  margin-left: 2%;
}
.graph{
  width: 60%;
 }
.main{
  display: flex;
  padding: 40px 104px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}
.process{
  display: flex;
  padding: 5% 0;
}
.ourservices{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3%;
}
.mainservices{
  background: #f5f5f5;
  padding-left: 104px;
}
#theytrustus, #aboutus, #ourservices, #a, span, .whyul{
  color: #414141;
  font-weight: 100;
 }
 /* .whyul, span, .partners li{
  width: 75%;
 } */
 /* div li, div p, h1{
  font-weight: 600;
 } */
.management{
width: 50%;
  align-self: center;
}
.maintitle{
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  color: #414141;
  font-weight: 600;
  font-size: 32px;
}
.complogo{
  width: 30%;
}
.graphimage{
  width: 60%;
  text-align: end;
  align-self: self-end;
}
.dots{
  padding-left: 10%;
  width: 70%;
}
.footer{
  display: flex;
  grid-auto-flow: column;
  justify-content: space-between;
  background: #231A3C;
  color: white;
  padding: 50px 105px;
}
.services h1{
  color: white;
}
.cards{
  display: grid;
  grid-auto-flow:column ;
  gap: 20px;
  margin-top: 30px;
}
.card{
  text-align: -webkit-center;
}

@media(max-width: 375px) {
 .main{
  padding: 0 5px;
 }
 .lines{
  padding: 0;
 }
 .mainservices, .app{
  padding: 20px;
 }
 .management img{
  width: 100%;
 }
}
@media(max-width: 590px) {
 #a{
  width: 170px;
  font-size: 20px;
 }
 .headerlogo, .ourservices{
  display: flex;
  flex-direction: column;
  align-items: center;
 }
 nav ul{
  margin-top: 10%;
  display: flex;
  flex-flow: column;
  align-items: center;
 }
.headerlogo ul li a{
  padding: 5% 0;
}
.headerlogo ul{
  padding-left: 0;
}
}
@media (max-width: 1024px) {
  .headerlogo li a {
    flex-direction: column;
    display: flex;
    text-align: center;
  }
  .dots{
    width: 85%;
    align-self: center;
    }
  h1, #a, #theytrustus, .norrr .demoo{
   width: 100%;
   font-size: 22px;
   height: 40px;
   }
  .partners li{
    font-size: 16px;
  }
 }
 
@media(max-width: 745px) {
  .footer{
    padding: 0;
  }
}
 @media (max-width: 900px) {
  .process, .main{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .graphimage{
    text-align: end;
  }
   .images{
    width: 100%;
   }
   .ourservices #dots{
    display: none;
   }
   .management {
    width: 100%;
    padding-bottom: 5%;
    align-self: center;
   }
}
header div nav ul li a:hover{
  transition: 0.1s;
  color: black;
  text-decoration: underline;
}
